<template>
  <v-overlay :value="loading" absolute :z-index="9999999">
    <v-progress-circular indeterminate color="primary" />
  </v-overlay>
</template>

<script>
export default {
  name: 'pibot-loading-overlay',
  props: {
    loading: {
      type: Boolean,
      default: true
    }
  }
}
</script>
